/* ModalCalendar.css */
.wrapper {
  width: 300px;
  border: 1px solid #d9d9d9; /* Replace with the desired border color */
  border-radius: 8px; /* Adjust border radius */
}

.calendar-header {
  padding: 8px;
}

.calendar-header-title {
  font-size: 1.25rem; /* Equivalent to Typography.Title level 4 */
  font-weight: 600;
}

.calendar-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-header-col {
  display: flex;
  align-items: center;
}

.calendar-button {
  border: none;
  box-shadow: none;
}

.calendar-button-next {
  margin-left: 8px;
}

.time-selector-container {
  margin-top: 16px;
}

.time-selector-content {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.time-selector-row {
  display: flex;
  justify-content: space-between;
}

.time-selector-actions {
  display: flex;
  gap: 8px;
}

.time-selector-button {
  border: none;
}

.time-selector-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}

.time-selector-footer-button {
  width: 40%;
  border-radius: 9999px;
}

/* Basic global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

/* General layout */
.container {
  padding: 20px;
}

/* Flex container for the calendar header */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header h1 {
  font-size: 30px;
  font-weight: normal;
}

.calendar-header .calendar-info {
  display: flex;
  gap: 20px;
}

/* Calendar events buttons */
.add-event-button {
  background-color: #007bff;
  color: white;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.search-input {
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #ddd;
  max-width: 300px;
}

/* Event modal styles */
.event-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.event-modal p {
  margin-bottom: 8px;
}

/* Modal and button styles */
.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.modal-footer button {
  padding: 10px 20px;
  border-radius: 25px;
}

.modal-footer .cancel-btn {
  background-color: #f5f5f5;
  color: #333;
}

.modal-footer .apply-btn {
  background-color: #007bff;
  color: white;
}

/* Container for the event details */
.event-container {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  margin-right: 1.25rem;
}

/* Placeholder for event image */
.event-img {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Event details section */
.event-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Title and date */
.event-title h2 {
  font-size: 18px;
  font-weight: bold;
}

.event-title p {
  font-size: 14px;
  color: #555;
}

/* Time display */
.event-time {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 14px;
}

/* Buttons container */
.event-buttons {
  display: flex;
  gap: 1rem;
}

/* Button styles */
.btn-outline {
  color: black;
  border: 1px solid black;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

.btn-primary {
  background: black;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Button hover effects */
.btn-outline:hover {
  background: black;
  color: white;
}

.btn-primary:hover {
  opacity: 0.8;
}

/* Header container */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

/* Calendar title */
.calendar-title {
  font-size: 30px;
  font-weight: normal;
}

/* Actions container */
.calendar-actions {
  display: flex;
  gap: 2rem;
  align-items: center;
}

/* Google Calendar status */
.calendar-status {
  background: #e5e5e5;
  padding: 4px 8px;
  border-radius: 50px;
  color: #979797;
  font-size: 15px;
}

/* Change option */
.calendar-change {
  text-decoration: underline;
  color: #979797;
  font-size: 15px;
  cursor: pointer;
}

/* Hover effect for change link */
.calendar-change:hover {
  color: black;
}

/* Main Calendar Container */
.calendar-container {
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
}

/* Calendar Subheader */
.calendar-subheader {
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-bottom: 32px;
}

/* Add Button */
.calendar-add-btn {
  max-width: 300px;
}

/* Filter Input */
.calendar-filter-input {
  max-width: 300px;
}

.fc-daygrid-event{
  color: black;
}