.container {
  background: #f5f5f5;
}
.headers {
  margin-bottom: 20px;
}
.contentWrapper {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }
}

.searchInput {
  max-width: 300px;
  margin-bottom: 16px;
}

.table-data {
  width: 100%;
}

.emptydata {
  margin: 40px 0 20px 0;
}
