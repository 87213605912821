.addcls {
  &_cont {
    border-radius: 10px;
    margin: 0 auto;
    padding: 20px;
    width: 70%;
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e0e0e0;
      h2 {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &_form {
      .form-group {
        margin-bottom: 24px;

        label {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          color: #333;
        }

        .title-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-size: 16px;
        }
        .media-section {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          .media-preview {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .image-preview-cont {
              position: relative;

              .uploaded-image {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 8px;
                border: 1px solid #ddd;
                img {
                  display: block;
                  max-width: 100%;
                  max-height: 200px;
                  object-fit: contain;
                }
              }
              .remove-image {
                position: absolute;
                top: -10px;
                right: -10px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #f44336;
                color: white;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                padding: 0;
                line-height: 1;
              }
            }
          }

          .media-upload {
            width: 100px;
            height: 100px;

            .upload-button {
              width: 100%;
              height: 100%;
              border: 2px dashed #ccc;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              color: #666;
              background: none;
              cursor: pointer;
              transition: all 0.3s ease;
            }

            .upload-button:hover {
              border-color: #888;
              color: #888;
            }
          }
        }
      }
    }
  }

  .image-cropper-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
  }

  .crop-container {
    max-width: 90vw;
    max-height: 70vh;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cropper-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    button {
      padding: 8px 20px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s ease;

      &.save-button {
        background: #4caf50;
      }

      &.save-button:hover {
        background: #45a049;
      }

      &.cancel-button {
        background: #f44336;
        color: white;
      }

      &.cancel-button:hover {
        background: #da190b;
      }
    }
  }
}

@media (max-width: 768px) {
  .addcls {
    &_cont {
      border-radius: 10px;
      margin: 0 auto;
      padding: 20px;
      width: 100%;
    }
  }

  .media-section {
    flex-direction: column;
  }

  .media-preview,
  .media-upload {
    width: 100%;
  }

  .category-section {
    flex-direction: column;
  }

  .new-cat-button {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .addcls {
    &_cont {
      border-radius: 10px;
      margin: 0 auto;
      padding: 20px;
      width: 100%;
    }
  }
}
