.Manageinstructor {
    display: flex;
    align-items: center;
    justify-content: center;
    .instform {
      width: 70%;
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        h2 {
          margin: 0;
        }
      }
      .instform_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
  
        .instform_content_details {
          display: flex;
          flex-direction: column;
          gap: 16px;
  
          .instform_content_details_top {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
  
            @media (max-width: 768px) {
              grid-template-columns: 1fr; // Stack fields in one column on smaller screens
            }
          }
        }
  
        .form_field {
          display: flex;
          flex-direction: column;
          gap: 4px;
  
          label {
            // font-weight: bold;
            // margin-bottom: 4px;
          }
  
          .error {
            color: red;
            font-size: 12px;
            margin-top: 4px;
          }
        }
  
        .instform_content_textarea {
          textarea {
            width: 100%;
          }
        }
  
        .phone-input-container {
          .react-tel-input {
            width: 100%;
          }
        }
      }
    }
    .instform_contentModal_data {
      margin-top: 10px;
      .weekly_schedule_container {
        padding: 16px;
  
        h2 {
          font-size: 1.5rem;
          margin-bottom: 8px;
        }
  
        p {
          margin-bottom: 16px;
          color: #666;
        }
  
        .weekly_schedule_day {
          margin-bottom: 20px;
  
          .weekly_schedule_day_header {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
  
            .day_name {
              margin: 0;
              width: 80px;
            }
          }
  
          .time_slots {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
  
            .time_slot {
              display: flex;
              align-items: center;
              gap: 10px;
  
              @media (max-width: 768px) {
                flex-direction: column;
                align-items: stretch;
              }
            }
  
            .add_slot_button {
              // margin-top: 10px;
            }
          }
        }
      }
    }
  }
  
  .phone-input-container {
    label {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
  
    .react-tel-input {
      margin-top: 0px;
      .form-control {
        height: 39px;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        transition: border-color 0.3s ease;
        &:hover {
          border-color: #4096ff !important;
          background-color: #ffffff !important;
        }
      }
    }
  }
  
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
    font-weight: 600;
  }
  
  .ant-tabs .ant-tabs-tab:hover {
    color: #000 !important;
  }
  .ant-tabs .ant-tabs-tab:focus {
    color: #000 !important;
  }
  
  .ant-tabs .ant-tabs-ink-bar {
    background: #000 !important;
  }
  
  @media (max-width: 768px) {
    .Manageinstructor {
      display: flex;
      align-items: center;
      justify-content: center;
      .instform {
        width: 90%;
      }
    }
  }
  
  @media (max-width: 1023px) {
    .Manageinstructor {
      display: flex;
      align-items: center;
      justify-content: center;
      .instform {
        width: 100%;
      }
    }
  }