.addproducts {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }
  }
  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &_left {
      width: 60%;
      // background-color: #FFF;
      border-radius: 10px;
      &_content {
        
        .form-group {
          margin-bottom: 24px;
          width: 100%;
          label {
            display: block;
            margin-top: 10px;
            margin-bottom: 8px;
            font-weight: 500;
            color: #333;
          }

          .title-input {
            width: 100%;
            padding: 10px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            font-size: 16px;
          }
          .media-section {
            display: flex;

            flex-wrap: wrap;

            .media-preview {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .image-preview-cont {
                position: relative;

                .uploaded-image {
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 8px;
                  border: 1px solid #ddd;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .remove-image {
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background: #f44336;
                  color: white;
                  border: none;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;
                  padding: 0;
                  line-height: 1;
                }
              }
            }

            .media-upload {
              width: 100%;
              height: 100px;

              .upload_btn {
                width: 200%;
                height: 100%;
                border: 2px dashed #ccc;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #666;
                background: none;
                cursor: pointer;
                transition: all 0.3s ease;
                padding: 20px;
              }

              .upload-button:hover {
                border-color: #888;
                color: #888;
              }
            }
          }
        }
      }
    }
    &_right {
      width: 38%;
    //   background-color: #fff;
      border-radius: 10px;
      .form-group {
        // margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        label {
          display: block;
          margin-top: 10px;
          margin-bottom: 8px;
          font-weight: 500;
          color: #333;
        }

       
    }
    .form-group1 {
        margin-bottom: 24px;
        width: 100%;
       
        label {
          display: block;
          margin-top: 10px;
          margin-bottom: 8px;
          font-weight: 500;
          color: #333;
        }

       
    }
    }
  }
}
