@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.App {
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
}

*{
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

.error {
  font-size: 12px;
  color: red;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eeeeee;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ant-table-body {
  scrollbar-width: thin !important;
}

.error-text{
  color: red;
}