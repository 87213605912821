// @import "../../GlobalStyles//variables.scss";
// @import "../../GlobalStyles//mixins.scss";

.custom-button {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px !important;
  gap: 5px;
  border-radius: 4px;
  &.primary {
    background: #000 !important;
    color: #fff;
    padding: 8px 20px !important;
    border: none !important;
  }
  &.secondary {
    background: #c3c3c3 !important;
    color: #fff;
    border: 1px solid #fff;
    padding: 8px 20px !important;
  }
}
