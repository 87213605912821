.product {
    background-color: #f5f5f5;
  
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h2 {
        margin: 0;
      }
    }
  
    &_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      background-color: #fff;
      padding: 10px 10px 5px 20px;
      border-radius: 10px 10px 0 0;
      &_search {
        display: flex;
        gap: 12px;
        input {
          width: 200px;
        }
      }
  
      &_custom {
        display: flex;
        gap: 8px;
  
        .tab {
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
          font-weight: normal;
          color: #000;
        }
  
        .tab.selected {
          background-color: #d3d3d3;
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
  