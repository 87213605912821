.layout {
  height: 100vh;
  overflow-y: hidden;
}

.sidebar {
  background-color: #fff !important;

  &_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    img {
      width: 60px;
      height: auto;
    }
  }
  ul.ant-menu {
    background-color: #fff !important;
  }
  li.ant-menu-item,
  li.ant-menu-submenu-title {
    color: #000 !important;
    height: 30px !important;
    &:hover {
      background-color: transparent !important;
    }
    &:active {
      background-color: #e3e3e3 !important;
    }

    &.ant-menu-item-selected {
      color: #000 !important;
      font-weight: 700 !important;
      background-color: #e3e3e3 !important;
    }
  }

  .ant-menu-submenu-title {
    height: 30px !important;
    &:hover {
      background-color: transparent !important;
    }
    &:active {
      background-color: #e3e3e3 !important;
    }
    &.ant-menu-item-selected {
      background-color: transparent !important;
      color: #000 !important;
    }
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background-color: transparent !important;
    color: #000 !important;
  }

  li.ant-menu-submenu {
    .ant-menu-submenu-arrow {
      color: #000 !important;
    }
    ul {
      background-color: #fff !important;
      li {
        color: #000 !important;
        &:hover {
          background-color: transparent !important;
        }
        &:active {
          background-color: #e3e3e3 !important;
        }
        &.ant-menu-item-selected {
          background-color: #e3e3e3 !important;
          color: #000 !important;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  &_right {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;
    &_user {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(24, 9, 188, 0.675);
      border-radius: 25px;
      height: 40px;
      width: 40px;
      cursor: pointer;
      h6 {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        letter-spacing: 0.5px;
      }
    }
  }
}
