.b_details{
    width: 100%;
   &_heading{
    margin-bottom: 20px;
   }
   &_content {
    margin-top: 20px;
    // background-color: #fff;
    padding: 10px;
    border-radius: 10px;
   
    &_bank {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 30px;
      margin-bottom: 20px;
    }
    &_btn{
        width: 100%;
       display: flex;
       justify-content: flex-end;
    }
  }
}