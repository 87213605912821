.booking {
  background-color: #f5f5f5;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background-color: #fff;
    padding: 10px 10px 5px 20px;
    border-radius: 10px 10px 0 0;
    &_search {
      display: flex;
      gap: 12px;
      input {
        width: 200px;
      }
    }

    &_custom {
      display: flex;
      gap: 8px;

      .tab {
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        font-weight: normal;
        color: #000;
      }

      .tab.selected {
        background-color: #d3d3d3;
        color: #000;
        font-weight: bold;
      }
    }
  }
  &_sec {
    margin: 10px 0 20px 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    &_card {
      display: flex;

      &_data {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e0e0e0;

        &:last-child {
          border-right: none;
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: center; // Center content horizontally
          text-align: center;

          h3 {
            margin: 0;
            font-size: 14px;
            color: #666;
            font-weight: 500;
          }

          h2 {
            margin: 5px 0 0; // Adjust spacing
            font-size: 20px;
            font-weight: bold;
            width: 100%; // Ensure h2 stretches fully
            text-align: left; // Align text left under h3
            padding-left: 2px; // Fine-tune alignment
          }
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    padding: 24px;
  }

  .class-details {
    border: 1px solid gray;
    padding: 10px;
    margin-top: 16px;
    border-radius: 10px;
    .detail-row {
      display: flex;
      margin-bottom: 12px;

      .label {
        font-weight: 600;
        width: 120px;
      }
    }
  }

  .reschedule-section {
    margin-top: 24px;

    h4 {
      margin-bottom: 16px;
    }

    .date-time-inputs {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
    }

    .reason-input {
      margin-bottom: 16px;
    }
  }
}
