$primary-color: #4b5563;
$secondary-color: #6b7280;
$border-color: #e5e7eb;
$background-color: #f3f4f6;

.dashboard {
  padding: 2rem;
  background-color: $background-color;
  min-height: 100vh;

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      color: $primary-color;
    }

    .tab-buttons {
      button {
        padding: 0.5rem 1rem;
        margin-left: 0.5rem;
        border: none;
        background: none;
        cursor: pointer;
        color: $secondary-color;

        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
      }
    }
  }

  .stats-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;

    .stat-card {
      background: white;
      padding: 1.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      .stat-icon {
        color: $secondary-color;
        margin-right: 1rem;
      }

      .stat-info {
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0;
          color: $primary-color;
        }

        p {
          margin: 0;
          color: $secondary-color;
          font-size: 0.875rem;
        }
      }
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 1.5rem;

    .chart-container {
      background: white;
      padding: 1.5rem;
      border-radius: 0.5rem;
      margin-top: 20px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 1rem;
        font-weight: 500;
        color: $primary-color;
        margin-bottom: 1rem;
      }
    }
  }
  .card {
    background: white;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 10px;
    text-align: center;
    .card-content {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        font-size: 30px;
      }
    }
  }
}
