.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &_cont {
    // width: 90%;
    // height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: antiquewhite;
    border-radius: 20px;
    &_sec {
      padding: 30px;
      display: flex;
      justify-content: space-between;
      &_left {
        // width: 95%;
        // height: 60vh;
        img {
          width: 100%;
          // height: calc(100vh - 70px);
          object-fit: cover;
          margin-top: 4px;
          border-radius: 20px;
        }
      }
      &_right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &_section {
          width: 80%;
          &_top {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 80px;
              height: auto;
              margin-bottom: 15px;
              border-radius: 50%;
            }
            h2 {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 20px;
            }
          }
          &_center {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 15px;
          }
          &_bottom {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
