// @import "../../GlobalStyles/variables.scss";
// @import "../../GlobalStyles/mixins.scss";

.forminput {
  span {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }

  .input-container {
    margin-top: 5px;
  }

  .ant-input,
  .ant-input-password input {
    font-weight: 600;
    color: #717171;
    font-size: 16px;

    &::placeholder {
      font-weight: 600;
      color: #717171;
      font-size: 16px;
    }
  }

  .ant-input[disabled],
  .ant-input-password input[disabled] {
    background-color: #fff; // Adjust as necessary
    color: #717171; // Maintain the same text color
    opacity: 1; // Prevent the default opacity for disabled inputs
    cursor: default !important; // Indicate it's disabled
  }
}
